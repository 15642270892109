import { merge, omit } from 'lodash'
import { HYDRATE } from 'next-redux-wrapper'
import {
	SET_ACCOUNT_CHALLENGE_SPACES,
	FILTER_ACCOUNT_CHALLENGE_SPACES,
	SET_ACCOUNT_CHALLENGE_SPACE,
	CLEAR_ACCOUNT_CHALLENGE_SPACE,
	SET_CHALLENGE_SPACE_CHALLENGES,
	UPDATE_CHALLENGE_SPACE_CHALLENGES,
	SET_CHALLENGE_SPACE_TEAMS,
	SET_CHALLENGE_SPACE_TEAM,
	UPDATE_CHALLENGE_SPACE_TEAM,
	SET_ACCOUNT_USERS,
	FILTER_ACCOUNT_USERS,
	REMOVE_ACCOUNT_USER,
	SET_ACCOUNT_COMPANIES,
	SET_ACCOUNT_EDUCATION_TYPES,
	SET_ACCOUNT_ORGANISATIONS,
	SET_ACCOUNT_NEW_SPACE_CONFIG,
	RESET_ACCOUNT_NEW_SPACE_CONFIG,
	FILTER_ACCOUNT_ORGANISATIONS,
	REMOVE_ACCOUNT_ORGANISATION,
	UPDATE_ORGANISATION_STATE,
	ADD_ACCOUNT_ORGANISATION,
	UPDATE_USER_STATE,
	FILTER_ACCOUNT_COMPANIES,
	SET_ACCOUNT_SUBJECTS,
	FILTER_ACCOUNT_SUBJECTS,
	ADD_ACCOUNT_USER,
	UPDATE_CHALLENGE_SPACE_STATE,
	UPDATE_CHALLENGE_STATE,
	SET_ACCOUNT_CHALLENGE,
	SET_ACCOUNT_CHALLENGES,
	REMOVE_CHALLENGE_SPACE_CHALLENGE,
	UPDATE_CHALLENGE_SPACE_CHALLENGE,
	REMOVE_CHALLENGE_SPACE_TEAM,
	SET_ACCOUNT_CHALLENGE_SPACE_DISTRIBUTION,
	SET_ACCOUNT_CHALLENGE_SPACE_RANKINGS,
	SET_ACCOUNT_CHALLENGE_SPACE_NOTE,
	REMOVE_ACCOUNT_CHALLENGE,
	UPDATE_ACCOUNT_CHALLENGE,
	FILTER_ACCOUNT_CHALLENGES,
	SET_ACCOUNT_TEAM,
	UPDATE_CHALLENGE_SPACE_CHALLENGE_RECRUITMENT_STATE,
	UPDATE_CHALLENGE_SPACE_CHALLENGE_RANKING_STATE,
	UPDATE_CHALLENGE_SPACE_TEAM_RANKING_STATE,
	UPDATE_CHALLENGE_SPACE_TEAM_REGISTRATION_STATE,
	UPDATE_CHALLENGE_SPACE_TEAM_FORMATION_STATE,
	UPDATE_CHALLENGE_SPACE_STUDENT_REGISTRATION_STATE,
	SET_CHALLENGE_SPACE_STUDENTS,
	SET_TEAM_CHALLENGE_RANKING,
	SET_CHALLENGE_TEAMS,
	SET_TEAM_RANKING,
	SET_ACCOUNT_CHALLENGE_SPACE_MAILINGS,
	SET_ACCOUNT_CHALLENGE_SPACE_TEAM_FORMATION_CONFIG,
	SET_SELECTED_COMPANY,
	UPDATE_SELECTED_COMPANY,
	UPDATE_CHALLENGE_SPACE_CHALLENGE_USERS,
	UPDATE_SELECTED_CHALLENGE_USERS,
	SET_ACCOUNT_CHALLENGE_NOTE,
	SET_TEAM_FORMATION_DEFAULT_QUESTIONS,
	CREATE_TEAM_FORMATION_DISTRIBUTION,
	GET_TEAM_FORMATION_DISTRIBUTION,
	GET_TEAM_FORMATION,
	GET_TEAM_FORMATION_CONFIG_WITH_DISTRIBUTION,
	PUT_TEAM_FORMATION_DISTRIBUTION,
	FINALIZE_TEAM_FORMATION,
	TEAM_FORMATION_INFO,
	DUPLICATE_CHALLENGE_SPACE,
	SET_LOADING,
	DEAUTHENTICATE,
	UPDATE_CHALLENGE_SPACE_EVALUATION_STATE,
} from '../actions'
import { AnyAction } from 'redux'
import { mapIdsToArrayForceAttributes, ObjectFilter } from 'utils/helpers'

export interface SpaceDistributionState {
	distribution: {}
	team: {}
	challenge: {}
	company: {}
	distributionItem: {}
	challengeRanking: {}
	challengeRankingItem: {}
	teamRanking: {}
	teamRankingItem: {}
}

export interface NewSpaceConfig {
	useChallenges: boolean
	useTeamFormation: boolean
	challengeTypes: string
	spaceSettings: {}
	challengeSettings: {}
	teamSettings: {}
}

export interface AccountState {
	meta: {}
	loading: boolean
	challengeSpace: {}
	challengeSpaceChallenges: {}
	challengeSpaceStudents: {}
	challengeSpaceDistribution: SpaceDistributionState | null
	challengeSpaceRankings: {} | null
	challengeSpaceTeams: {}
	challengeSpaceMailings: {}
	challengeSpaceNote: ''
	newChallengeSpaceConfig: NewSpaceConfig
	newTeamFormationConfig: {} | null
	teamFormationDefaultQuestion: {}
	teamFormationDistributionMeta: {}
	teamFormationDistribution: {}
	teamFormationQuestionAnswer: {}
	teamFormationUsers: {}
	teamFormationUserProfiles: {}
	teamFormationInfo: {}
	challengeRanking: {}
	selectedTeam: {}
	selectedCompany: {}
	selectedChallengeSpace: {
		attributes?: any
	}
	distributionChallenges: {}
	selectedChallenge: {}
	selectedChallengeTeamRequirements: {}
	selectedChallengeNote: ''
	challengeRankingItem: {}
	teamRanking: {}
	teamRankingItem: {}
	challengeTeams: {}
	challenge: {}
	subject: {}
	company: {}
	team: {}
	address: {}
	user: {}
	admin: {}
	organisation: {}
	educationType: {}
	evaluationQuestions: {}
}

const initialState: AccountState = {
	meta: {},
	loading: false,
	challengeSpace: {},
	challengeSpaceChallenges: {},
	challengeSpaceStudents: {},
	challengeSpaceDistribution: null,
	challengeSpaceRankings: null,
	challengeSpaceTeams: {},
	challengeSpaceMailings: {},
	challengeSpaceNote: '',
	newChallengeSpaceConfig: {
		useChallenges: true,
		useTeamFormation: true,
		challengeTypes: '',
		spaceSettings: {},
		challengeSettings: {},
		teamSettings: {
			applicationType: 'team',
			teamSizeLimit: 999,
			registrationStartDate: '',
			registrationEndDate: '',
		},
	},
	distributionChallenges: null,
	newTeamFormationConfig: null,
	teamFormationDistribution: {},
	teamFormationDistributionMeta: {},
	teamFormationQuestionAnswer: {},
	teamFormationUsers: {},
	teamFormationUserProfiles: {},
	teamFormationDefaultQuestion: {},
	teamFormationInfo: {},
	challengeRanking: {},
	selectedTeam: {},
	selectedCompany: {},
	selectedChallengeSpace: {},
	selectedChallenge: {},
	selectedChallengeTeamRequirements: {},
	selectedChallengeNote: '',
	challengeRankingItem: {},
	teamRanking: {},
	teamRankingItem: {},
	challengeTeams: {},
	challenge: {},
	subject: {},
	company: {},
	team: {},
	address: {},
	user: {},
	admin: {},
	organisation: {},
	educationType: {},
	evaluationQuestions: {},
}

export const accountReducer = (
	state: AccountState = initialState,
	action: AnyAction
) => {
	switch (action.type) {
		case HYDRATE:
			// Attention! This will overwrite client state! Real apps should use proper reconciliation.
			return { ...state, ...action.payload.account }
		case SET_LOADING:
			return { ...state, loading: action.response }
		// return state
		case SET_ACCOUNT_CHALLENGE_SPACES:
		case SET_ACCOUNT_CHALLENGES:
		case SET_ACCOUNT_USERS:
		case SET_ACCOUNT_EDUCATION_TYPES:
		case SET_ACCOUNT_ORGANISATIONS:
		case ADD_ACCOUNT_ORGANISATION:
		case ADD_ACCOUNT_USER:
		case SET_TEAM_FORMATION_DEFAULT_QUESTIONS:
		case DUPLICATE_CHALLENGE_SPACE:
			return merge({}, state, merge({}, action.response))
		case SET_ACCOUNT_COMPANIES:
			return {
				...state,
				...merge({}, action.response),
			}
		case SET_ACCOUNT_SUBJECTS:
			return {
				...state,
				meta: {
					...state.meta,
					['/subjects']: action.response.meta['/subjects'],
				},
				subject: action.response.subject,
			}
		case SET_ACCOUNT_CHALLENGE_SPACE:
			return {
				...state,
				meta: merge(state.meta, action.response.meta),
				selectedChallengeSpace: action.response.challengeSpace
					? Object.keys(action.response.challengeSpace).map(
							(key) => action.response.challengeSpace[key]
					  )[0]
					: {},
				subject: { ...state.subject, ...action.response.subject },
				address: { ...state.address, ...action.response.address },
				admin: { ...state.admin, ...action.response?.admin },
				user: { ...state.user, ...action.response.user },
				evaluationQuestions: {
					...ObjectFilter(action.response?.question ?? {}, ([, obj]) => {
						return ['evaluation-space', 'evaluation-team'].includes(
							obj.attributes.context
						)
					}),
				},
				organisation: {
					...state.organisation,
					...action.response.organisation,
				},
			}
		case CLEAR_ACCOUNT_CHALLENGE_SPACE:
			return {
				...state,
				meta: {},
				selectedChallengeSpace: {},
				challenges: {},
				subject: {},
				address: {},
				organisation: {},
				evaluationQuestions: {},
			}
		case SET_ACCOUNT_TEAM:
			return {
				...state,
				meta: merge(state.meta, action.response.meta),
				selectedTeam: action.response.team
					? Object.keys(action.response.team).map(
							(key) => action.response.team[key]
					  )[0]
					: {},
				user: { ...state.user, ...action.response.user },
				subject: { ...state.subject, ...action.response.subject },
				challengeSpace: {
					...state.challengeSpace,
					...action.response.challengeSpace,
				},
			}
		case SET_SELECTED_COMPANY:
		case UPDATE_SELECTED_COMPANY:
			return {
				...state,
				meta: merge(state.meta, action.response.meta),
				selectedCompany: {
					...state.selectedCompany,
					...merge(
						action.response.address
							? action.response.address[Object.keys(action.response.address)[0]]
							: {},
						action.response.company
							? action.response.company[Object.keys(action.response.company)[0]]
							: {},
						{
							attributes: {
								ownerId: action.response.user
									? action.response.user[Object.keys(action.response.user)[0]]
											.id
									: null,
							},
						}
					),
				},
			}
		case SET_ACCOUNT_CHALLENGE:
			return {
				...state,
				meta: merge(state.meta, action.response.meta),
				selectedChallenge: action.response.challenge
					? Object.keys(action.response.challenge).map(
							(key) => action.response.challenge[key]
					  )[0]
					: {},
				selectedChallengeTeamRequirements: action.response
					.teamFormationTeamRequirement
					? Object.values(action.response.teamFormationTeamRequirement)
					: [],
				company: { ...state.company, ...action.response.company },
				address: { ...state.address, ...action.response.address },
				organisation: {
					...state.organisation,
					...action.response.organisation,
				},
				user: { ...state.user, ...action.response.user },
				team: { ...state.team, ...action.response.team },
				challengeSpace: {
					...state.challengeSpace,
					...action.response.challengeSpace,
				},
				questions: action.response.question
					? Object.values(action.response.question)
					: [],
				answers: action.response.questionAnswer
					? Object.values(action.response.questionAnswer)
					: [],
			}
		case SET_ACCOUNT_CHALLENGE_NOTE:
			return {
				...state,
				selectedChallengeNote: action.response.challengeNote
					? action.response.challengeNote[
							Object.keys(action.response.challengeNote)[0]
					  ].attributes.note
					: '',
			}
		case SET_TEAM_CHALLENGE_RANKING:
			return {
				...state,
				challengeRanking: action.response.challengeRanking
					? action.response.challengeRanking
					: {},
				challengeRankingItem: action.response.challengeRankingItem
					? action.response.challengeRankingItem
					: {},
				challengeRankingUser: action.response.user ? action.response.user : {},
			}
		case SET_TEAM_RANKING:
			return {
				...state,
				teamRanking: action.response.teamRanking
					? action.response.teamRanking
					: {},
				teamRankingItem: action.response.teamRankingItem
					? action.response.teamRankingItem
					: {},
			}
		case FILTER_ACCOUNT_CHALLENGE_SPACES:
			return {
				...state,
				challengeSpace: action?.response?.challengeSpace ?? {},
				user: action?.response?.user ?? {},
				organisation: action?.response?.organisation ?? {},
				subject: action?.response?.subject ?? {},
				admin: { ...state.admin, ...action.response?.admin },
				meta: {
					...state.meta,
					['/challenge-spaces']: action.response.meta['/challenge-spaces'],
				},
			}
		case FILTER_ACCOUNT_CHALLENGES:
			return {
				...state,
				challenge: action.response.challenge ? action.response.challenge : {},
				meta: {
					...state.meta,
					['/challenges']: action.response.meta['/challenges'],
				},
			}
		case FILTER_ACCOUNT_USERS:
			return {
				...state,
				user: action.response.user ? action.response.user : {},
				meta: {
					...state.meta,
					['/users']: action.response.meta['/users'],
				},
			}
		case REMOVE_ACCOUNT_USER:
			return {
				...state,
				user: omit(state.user, action.response.userId),
			}
		case FILTER_ACCOUNT_ORGANISATIONS:
			return {
				...state,
				organisation: action.response.organisation
					? action.response.organisation
					: {},
				address: { ...state.address, ...action.response.address },
				meta: {
					...state.meta,
					['/organisations']: action.response.meta['/organisations'],
				},
			}
		case REMOVE_ACCOUNT_ORGANISATION:
			return {
				...state,
				organisation: omit(state.organisation, action.response.organisationId),
			}
		case UPDATE_ORGANISATION_STATE:
			return {
				...state,
				organisation: {
					...state.organisation,
					[action.response.organisationId]: {
						...state.organisation[action.response.organisationId],
						attributes: {
							...state.organisation[action.response.organisationId].attributes,
							state: action.response.state,
						},
					},
				},
			}
		case FILTER_ACCOUNT_COMPANIES:
			return {
				...state,
				company: action.response.company ? action.response.company : {},
				meta: {
					...state.meta,
					['/companies']: action.response.meta['/companies'],
				},
			}
		case FILTER_ACCOUNT_SUBJECTS:
			return {
				...state,
				subject: action.response.subject ? action.response.subject : {},
				meta: {
					...state.meta,
					['/subjects']: action.response.meta['/subjects'],
				},
			}
		case SET_CHALLENGE_SPACE_CHALLENGES:
			return {
				...state,
				meta: merge(state.meta, action.response.meta),
				challengeSpaceChallenges: action.response.challenge
					? action.response.challenge
					: {},
				company:
					state?.company && action.response?.company
						? { ...state.company, ...action.response.company }
						: {},
				organisation: {
					...state.organisation,
					...action.response.organisation,
				},
				address: { ...state.address, ...action.response.address },
				team: { ...state.team, ...action.response.team },
				user: { ...state.user, ...action.response.user },
				admin: { ...state.admin, ...action.response?.admin },
			}
		case UPDATE_CHALLENGE_SPACE_CHALLENGES:
			return {
				...state,
				meta: merge(state.meta, action.response.meta),
				company: { ...state.company, ...action.response.company },
				address: { ...state.address, ...action.response.address },
				user: { ...state.user, ...action.response.user },
				admin: { ...state.admin, ...action.response?.admin },
				team: { ...state.team, ...action.response.team },
				challengeSpaceChallenges: action.response.challenge
					? action.response.challenge
					: {},
			}
		case REMOVE_CHALLENGE_SPACE_CHALLENGE:
			return {
				...state,
				challengeSpaceChallenges: omit(
					state.challengeSpaceChallenges,
					action.response.challengeId
				),
			}
		case UPDATE_CHALLENGE_SPACE_CHALLENGE:
			return {
				...state,
				challengeSpaceChallenges: {
					...state.challengeSpaceChallenges,
					[action.challengeId]: {
						...state.challengeSpaceChallenges[action.challengeId],
						attributes: {
							...state.challengeSpaceChallenges[action.challengeId].attributes,
							...action.response.challenge[action.challengeId].attributes,
						},
					},
				},
			}
		case UPDATE_CHALLENGE_SPACE_CHALLENGE_USERS:
			return {
				...state,
				challengeSpaceChallenges: {
					...state.challengeSpaceChallenges,
					[action.challengeId]: {
						...state.challengeSpaceChallenges[action.challengeId],
						relationships: {
							...state.challengeSpaceChallenges[action.challengeId]
								.relationships,
							...action.response.challenge[action.challengeId].relationships,
						},
					},
				},
				user: { ...state.user, ...action.response.user },
				admin: { ...state.admin, ...action.response?.admin },
				company: { ...state.company, ...action.response.company },
			}
		case UPDATE_SELECTED_CHALLENGE_USERS:
			return {
				...state,
				selectedChallenge: {
					...state.selectedChallenge,
					...action.response.challenge[action.challengeId],
					relationships: {
						...state.selectedChallenge['relationships'],
						...action.response.challenge[action.challengeId].relationships,
					},
				},
				company: { ...state.company, ...action.response?.company },
				address: { ...state.address, ...action.response?.address },
				organisation: {
					...state.organisation,
					...action.response?.organisation,
				},
				user: { ...state.user, ...action.response?.user },
			}
		case UPDATE_CHALLENGE_SPACE_CHALLENGE_RECRUITMENT_STATE:
		case UPDATE_CHALLENGE_SPACE_CHALLENGE_RANKING_STATE:
		case UPDATE_CHALLENGE_SPACE_TEAM_RANKING_STATE:
		case UPDATE_CHALLENGE_SPACE_TEAM_REGISTRATION_STATE:
		case UPDATE_CHALLENGE_SPACE_STUDENT_REGISTRATION_STATE:
		case UPDATE_CHALLENGE_SPACE_EVALUATION_STATE:
			return {
				...state,
				selectedChallengeSpace: {
					...state.selectedChallengeSpace,
					attributes: {
						...state.selectedChallengeSpace['attributes'],
						...action.response.challengeSpace[action.challengeSpaceId]
							.attributes,
					},
				},
			}
		case SET_ACCOUNT_CHALLENGE_SPACE_DISTRIBUTION:
			return {
				...state,
				selectedChallengeSpace: {
					...state.selectedChallengeSpace,
					id: action.response.challengeSpace[action.challengeSpaceId].id,
					type: action.response.challengeSpace[action.challengeSpaceId].type,
					relationships: {
						...state.selectedChallengeSpace['relationships'],
						...action.response.challengeSpace[action.challengeSpaceId]
							.relationships,
					},
					attributes: {
						...state.selectedChallengeSpace['attributes'],
						...action.response.challengeSpace[action.challengeSpaceId]
							.attributes,
					},
				},
				company: { ...state.company, ...action.response.company },
				organisation: {
					...state.organisation,
					...action.response?.organisation,
				},
				challengeSpaceDistribution: action.response.distribution
					? {
							distribution: Object.keys(action.response.distribution).map(
								(key) => action.response.distribution[key]
							)[0],
							distributionItem: action.response.distributionItem
								? action.response.distributionItem
								: {},
							challengeRanking: action.response.challengeRanking
								? action.response.challengeRanking
								: {},
							challengeRankingItem: action.response.challengeRankingItem
								? action.response.challengeRankingItem
								: {},
							teamRanking: action.response.teamRanking
								? action.response.teamRanking
								: {},
							teamRankingItem: action.response.teamRankingItem
								? action.response.teamRankingItem
								: {},
							team: action.response.team ? action.response.team : {},
							challenge: action.response.challenge
								? action.response.challenge
								: {},
							company: action.response.company ? action.response.company : {},
					  }
					: null,
			}
		case SET_ACCOUNT_CHALLENGE_SPACE_RANKINGS:
			return {
				...state,
				challengeSpaceRankings: action.response,
			}
		case SET_ACCOUNT_NEW_SPACE_CONFIG:
			return {
				...state,
				newChallengeSpaceConfig: {
					...state.newChallengeSpaceConfig,
					...action.response,
				},
			}
		case RESET_ACCOUNT_NEW_SPACE_CONFIG:
			return {
				...state,
				newChallengeSpaceConfig: {
					useChallenges: true,
					useTeamFormation: true,
					challengeTypes: '',
					spaceSettings: {},
					challengeSettings: {},
					teamSettings: {},
				},
			}
		case SET_CHALLENGE_TEAMS:
			return {
				...state,
				challengeTeams: action.response.challengeTeam
					? action.response.challengeTeam
					: {},
				meta: merge(state.meta, action.response.meta),
				team: { ...state.team, ...action.response.team },
			}
		case REMOVE_ACCOUNT_CHALLENGE:
			return {
				...state,
				challenge: omit(state.challenge, action.response.challengeId),
			}
		case UPDATE_ACCOUNT_CHALLENGE:
			if (state.challenge[action.challengeId]) {
				return {
					...state,
					challenge: {
						...state.challenge,
						[action.challengeId]: action.response.challenge[action.challengeId],
					},
				}
			} else {
				return state
			}
		case SET_CHALLENGE_SPACE_TEAMS:
			return {
				...state,
				meta: merge(state.meta, action.response.meta),
				challengeSpaceTeams: action.response.team ? action.response.team : {},
			}
		case SET_CHALLENGE_SPACE_STUDENTS:
			return {
				...state,
				meta: merge(state.meta, action.response.meta),
				challengeSpaceStudents: action.response.user
					? action.response.user
					: {},
			}
		case SET_ACCOUNT_CHALLENGE_SPACE_MAILINGS:
			return {
				...state,
				meta: merge(state.meta, action.response.meta),
				challengeSpaceMailings: action.response.challengeSpaceMailing
					? action.response.challengeSpaceMailing
					: {},
			}
		case SET_ACCOUNT_CHALLENGE_SPACE_NOTE:
			return {
				...state,
				challengeSpaceNote: action.response.challengeSpaceNote
					? action.response.challengeSpaceNote[
							Object.keys(action.response.challengeSpaceNote)[0]
					  ].attributes.note
					: '',
			}
		case SET_CHALLENGE_SPACE_TEAM:
			return {
				...state,
				meta: merge(state.meta, action.response.meta),
				challengeSpaceTeams: {
					...state.challengeSpaceTeams,
					...action.response.team,
				},
				user: { ...state.user, ...action.response.user },
				organisation: {
					...state.organisation,
					...action.response.organisation,
				},
				subject: { ...state.subject, ...action.response.subject },
			}
		case SET_ACCOUNT_CHALLENGE_SPACE_TEAM_FORMATION_CONFIG:
			return {
				...state,
				newTeamFormationConfig:
					action.response && action.response.teamFormation
						? {
								...action.response.teamFormation[
									Object.keys(action.response.teamFormation)[0]
								],
								filters: action.response.teamFormationFilter
									? mapIdsToArrayForceAttributes(
											action.response.teamFormationFilter
									  )
									: [],
								questions: [
									...(action.response.teamFormationQuestion
										? Object.keys(action.response.teamFormationQuestion)
												.map((k) => {
													return {
														...action.response.teamFormationQuestion[k]
															.attributes,
														id: k,
													}
												})
												.sort((a, b) => {
													return a.listPosition - b.listPosition
												})
										: []),
								],
						  }
						: {
								attributes: {
									teamCount: 1,
									registrationEndDate: '',
									state: 'closed',
									registrationStartDate: '',
									useChallenges: false,
									matchDirectly: false,
								},
								filters: [],
								questions: [],
						  },
				teamFormationDistribution:
					action.response && action.response.teamFormationDistribution
						? {
								...action.response.teamFormationDistribution[
									Object.keys(action.response.teamFormationDistribution)[0]
								],
						  }
						: {},
				...(action.response.userProfile
					? {
							teamFormationUserProfiles: {
								...action.response.userProfile,
							},
					  }
					: {
							teamFormationUserProfiles: {},
					  }),
				teamFormationUserProfilesInTeam: action.response.userProfileInteam
					? {
							...action.response.userProfileInteam,
					  }
					: {},
				/* DEBATE: lets decide what is the best ways to store data in redux
				teamFormationUser: [
					...(action.response.user
						? Object.keys(action.response.user).map(
								(k) => action.response.user[k]
						  )
						: []),
				],
				*/
				teamFormationUsers: {
					...action.response.user,
				},
			}
		case UPDATE_CHALLENGE_SPACE_TEAM_FORMATION_STATE:
			return {
				...state,
				newTeamFormationConfig: {
					...state.newTeamFormationConfig,
					...(action.response && action.response.teamFormation
						? {
								...action.response.teamFormation[
									Object.keys(action.response.teamFormation)[0]
								],
						  }
						: null),
				},
			}
		case UPDATE_CHALLENGE_SPACE_TEAM:
			return {
				...state,
				selectedTeam: {
					...state.selectedTeam,
					...action.response.team[action.teamId],
				},
				challengeSpaceTeams: {
					...state.challengeSpaceTeams,
					[action.teamId]: action.response.team[action.teamId],
				},
			}
		case REMOVE_CHALLENGE_SPACE_TEAM:
			return {
				...state,
				challengeSpaceTeams: omit(
					state.challengeSpaceTeams,
					action.response.teamId
				),
			}
		case UPDATE_USER_STATE:
			return {
				...state,
				user: {
					...state.user,
					[action.response.userId]: {
						...state.user[action.response.userId],
						attributes: {
							...state.user[action.response.userId]?.attributes,
							state: action.response.state,
						},
					},
				},
			}
		case UPDATE_CHALLENGE_SPACE_STATE:
			return {
				...state,

				challengeSpace:
					Object.values(state.challengeSpace).length > 0 &&
					state.challengeSpace[action.response.challengeSpaceId]
						? {
								...state.challengeSpace,
								[action.response.challengeSpaceId]: {
									...state.challengeSpace[action.response.challengeSpaceId],
									attributes: {
										...state.challengeSpace[action.response.challengeSpaceId]
											.attributes,
										state: action.response.state,
									},
								},
						  }
						: { ...state.challengeSpace },

				selectedChallengeSpace:
					Object.values(state.selectedChallengeSpace).length > 0
						? {
								...state.selectedChallengeSpace,
								attributes: {
									...state.selectedChallengeSpace.attributes,
									state: action.response.state,
								},
						  }
						: {},
			}
		case UPDATE_CHALLENGE_STATE:
			return {
				...state,
				challengeSpaceChallenges: {
					...state.challengeSpaceChallenges,
					[action.response.challengeId]: {
						...state.challengeSpaceChallenges[action.response.challengeId],
						attributes: {
							...state.challengeSpaceChallenges[action.response.challengeId]
								.attributes,
							state: action.response.state,
						},
					},
				},
			}
		case CREATE_TEAM_FORMATION_DISTRIBUTION:
			return {
				...state,

				distributionChallenges: {
					...action.response.challenge,
				},

				company: {
					...state.company,
					...action.response.company,
				},

				newTeamFormationConfig: {
					...action.response.teamFormation[
						Object.keys(action.response.teamFormation)[0]
					],
					questions: [
						...(action.response.teamFormationQuestion
							? Object.keys(action.response.teamFormationQuestion)
									.map((k) => {
										return {
											...action.response.teamFormationQuestion[k].attributes,
											id: k,
										}
									})
									.sort((a, b) => {
										return a.listPosition - b.listPosition
									})
							: []),
					],
				},

				teamFormationDistribution: {
					...action.response.teamFormationDistribution[
						Object.keys(action.response.teamFormationDistribution)[0]
					],
				},

				teamFormationQuestion: {
					...action.response.teamFormationQuestion,
				},

				teamFormationQuestionAnswer: {
					...action.response.teamFormationQuestionAnswer,
				},

				teamFormationUsers: {
					...action.response.user,
				},
				...(action.response.userProfile
					? {
							teamFormationUserProfiles: {
								...action.response.userProfile,
							},
					  }
					: {}),
				...(action.response.userProfileInteam
					? {
							teamFormationUserProfilesInTeam: {
								...action.response.userProfileInteam,
							},
					  }
					: {}),
			}
		case GET_TEAM_FORMATION_DISTRIBUTION:
			return {
				...state,

				distributionChallenges: {
					...action.response.challenge,
				},

				company: {
					...state.company,
					...action.response.company,
				},

				organisation: {
					...state.organisation,
					...action.response?.organisation,
				},

				teamFormationDistribution: {
					...action.response.teamFormationDistribution[
						Object.keys(action.response.teamFormationDistribution)[0]
					],
				},

				teamFormationQuestion: {
					...action.response.teamFormationQuestion,
				},

				teamFormationQuestionAnswer: {
					...action.response.teamFormationQuestionAnswer,
				},

				teamFormationUsers: {
					...action.response.user,
				},

				...(action.response.userProfile
					? {
							teamFormationUserProfiles: {
								...action.response.userProfile,
							},
					  }
					: {}),
			}
		case GET_TEAM_FORMATION:
			return {
				...state,
				newTeamFormationConfig: {
					...action.response.teamFormation[
						Object.keys(action.response.teamFormation)[0]
					],
					questions: [
						...(action.response.teamFormationQuestion
							? Object.keys(action.response.teamFormationQuestion)
									.map((k) => {
										return {
											...action.response.teamFormationQuestion[k].attributes,
											id: k,
										}
									})
									.sort((a, b) => {
										return a.listPosition - b.listPosition
									})
							: []),
					],
				},
				teamFormationDistribution: {
					...action.response.teamFormationDistribution[
						Object.keys(action.response.teamFormationDistribution)[0]
					],
				},
			}
		case GET_TEAM_FORMATION_CONFIG_WITH_DISTRIBUTION: {
			return {
				...state,
				newTeamFormationConfig: {
					...action.response.teamFormation[
						Object.keys(action.response.teamFormation)[0]
					],
				},
				questions: [
					...(action.response.teamFormationQuestion
						? Object.keys(action.response.teamFormationQuestion)
								.map((k) => {
									return {
										...action.response.teamFormationQuestion[k].attributes,
										id: k,
									}
								})
								.sort((a, b) => {
									return a.listPosition - b.listPosition
								})
						: []),
				],
			}
		}

		case PUT_TEAM_FORMATION_DISTRIBUTION: {
			return {
				...state,

				distributionChallenges: {
					...action.response.challenge,
				},

				company: {
					...state.company,
					...action.response.company,
				},

				organisation: {
					...state.organisation,
					...action.response.organisation,
				},

				newTeamFormationConfig: {
					...action.response.teamFormation[
						Object.keys(action.response.teamFormation)[0]
					],
					questions: [
						...(action.response.teamFormationQuestion
							? Object.keys(action.response.teamFormationQuestion)
									.map((k) => {
										return {
											...action.response.teamFormationQuestion[k].attributes,
											id: k,
										}
									})
									.sort((a, b) => {
										return a.listPosition - b.listPosition
									})
							: []),
					],
				},

				teamFormationDistribution: {
					...action.response.teamFormationDistribution[
						Object.keys(action.response.teamFormationDistribution)[0]
					],
				},

				teamFormationQuestion: {
					...action.response.teamFormationQuestion,
				},

				teamFormationQuestionAnswer: {
					...action.response.teamFormationQuestionAnswer,
				},

				teamFormationUsers: {
					...action.response.user,
				},

				...(action.response.userProfile
					? {
							teamFormationUserProfiles: {
								...action.response.userProfile,
							},
					  }
					: {}),
			}
		}

		case TEAM_FORMATION_INFO: {
			return {
				...state,
				teamFormationInfo: {
					...action.response.teamFormation[
						Object.keys(action.response.teamFormation)[0]
					].attributes,
					numProfiles: action?.response?.userProfileFiltered
						? Object.keys(action?.response?.userProfileFiltered)?.length
						: 0,
				},
			}
		}

		case FINALIZE_TEAM_FORMATION: {
			return {
				...state,

				newTeamFormationConfig: {
					...state.newTeamFormationConfig,

					...action.response.teamFormation[
						Object.keys(action.response.teamFormation)[0]
					],

					questions: [
						...(action.response.teamFormationQuestion
							? Object.keys(action.response.teamFormationQuestion)
									.map((k) => {
										return {
											...action.response.teamFormationQuestion[k].attributes,
											id: k,
										}
									})
									.sort((a, b) => {
										return a.listPosition - b.listPosition
									})
							: []),
					],
				},
			}
		}
		case DEAUTHENTICATE:
			return initialState

		default:
			return state
	}
}
